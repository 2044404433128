<template>
  <div class="coianter" style="background-color: white">
    <div>
      <div>
        <div class="xuanze">
          <el-select @change="valueTo" v-model="value" placeholder="点击下载格式文件">
            <el-option label="下载pdf格式" :value="'1'" @click="getPdf()">下载pdf格式
            </el-option>
            <el-option label="下载word格式" :value="'2'" @click="getWord()">下载word格式
            </el-option>
          </el-select>

        </div>
        <div id="pdfDom" class="" style="padding-bottom: 100px; width: 100%; background: white">
          <div class="" style="align: center; padding-top: 10px">
            <div class="">
              <!-- padding-left:30px; -->
              <div id="tableHead" class="" style="padding: 30px">
                <div class="" style="
                    padding-bottom: 20px;
                    text-align: center;
                    font-size: 26px;
                  ">
                  江苏省工程建设企业技术标准认证公告申请表
                </div>
                <table>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">标准名称</td>
                    <td class="tdcontent" colspan="2">{{ form.proName }}</td>
                    <td rowspan="2" class="tdtitle">类型</td>
                    <td colspan="2">
                      <span v-if="form.biaozhunType == 1">标准</span>
                      <span v-if="form.biaozhunType == 2">制定</span>
                    </td>
                    <!-- <td style="width:100px;"> </td> -->
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">英文名称</td>
                    <td colspan="2">{{ form.USname }}</td>
                    <td colspan="2">
                      <span v-if="form.zhidingType == 1">制定</span>
                      <span v-if="form.zhidingType == 2">修订</span>
                    </td>
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle" style="width: 260px">编制单位</td>
                    <td colspan="2">{{ form.firstUnit }}</td>
                    <td class="tdtitle">地址(邮编)</td>
                    <td colspan="2">{{ form.firstAddress }}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">单位负责人</td>
                    <td style="width: 320px">{{ form.firstHandler }}</td>
                    <td class="tdtitle">联系电话</td>
                    <td class="" style="width: 100px">{{ form.firstTel }}</td>
                    <td class="tdtitle">电子邮箱</td>
                    <td class="" style="width: 380px">{{ form.firstEmail }}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">项目负责人</td>
                    <td>{{ form.firstProHandler }}</td>
                    <td class="tdtitle">联系电话</td>
                    <td>{{ form.firstProHandlerTel }}</td>
                    <td class="tdtitle">电子邮箱</td>
                    <td>{{ form.firstProHandlerEmail }}</td>
                  </tr>
                  <tr class="tableTitle-tr">
                    <td class="tdtitle">项目联系人</td>
                    <td>{{ form.firstProContact }}</td>
                    <td class="tdtitle">联系电话</td>
                    <td>{{ form.firstProContactTel }}</td>
                    <td class="tdtitle">电子邮箱</td>
                    <td>{{ form.firstProContactEmail }}</td>
                  </tr>

                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// projectTableInfo,
import { getBiaozhunDetail } from "@api/project";
import JiangSu from "@assets/jiangsu.json";

import moment from "moment";
export default {
  data() {
    return {
      value: "",
      isShow: false,
      htmlTitle: "页面导出PDF文件名",
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      form: {
        stars: 1,
        shencha: "",
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      value1: "",
      value2: "",
      value3: "",
      activeName: "first",
      tableData: [],
      tableData2: [
        {
          start_time: "",
          endtime: "",
          worktext: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext2: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext3: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext4: "",
        },
        {
          start_time: "",
          endtime: "",
          worktext5: "",
        },
      ],
      textName: "",
      id: "",
      token: "",
      page: 1, //第几页
      size: 3, //一页多少条
      total: 0, //总条目数
    };
  },
  mounted() {
    this.getIdAndToken();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    valueTo() {
      if (this.value == "2") {
        this.getWord();
      } else {
        this.getPdf();
      }
    },
    momentBytime(date) {
      let y = moment(date).format("YYYY-MM-DD");
      return y;
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.tableData = res.data.fu; //主要起草人员展开无分页
          console.log(res, '数据');
          this.tableData2[0].start_time = res.data.start_time1;
          this.tableData2[0].endtime = res.data.endtime1;
          this.tableData2[1].start_time = res.data.start_time2;
          this.tableData2[1].endtime = res.data.endtime2;
          this.tableData2[2].start_time = res.data.start_time3;
          this.tableData2[2].endtime = res.data.endtime3;
          this.tableData2[3].start_time = res.data.start_time4;
          this.tableData2[3].endtime = res.data.endtime4;
          this.tableData2[4].start_time = res.data.start_time5;
          this.tableData2[4].endtime = res.data.endtime5;
        })
        .catch((err) => {
          console.log(err);
        });
      // htmlToPdf.install
    },
    getWord() {
      window.open(
        "https://api.gbservice.cn/api/standard/word?id=" + this.form.id,
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
/deep/ .el-input {
  width: 250px;
}

/deep/.el-table th.el-table__cell>.cell {
  color: black;
}

/deep/.el-table th.el-table__cell {
  border-bottom: 1px solid black !important;
  border-right: 1px solid black !important;
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

/deep/.xuanze .el-input__inner {
  background: #67c23a;
  color: #ffffff;
}

/deep/.xuanze .el-input {
  width: 163px;
}

/deep/.xuanze .el-select .el-input.is-focus .el-input__inner {
  border-color: #67c23a;
}

/deep/.xuanze .el-select__caret {
  color: #ffffff;
}

/deep/.xuanze .el-select-dropdown__item.selected {}

/deep/.xuanze .el-input__inner::placeholder {
  color: #ffffff;
}

/deep/.xuanze .el-cascader-menu__wrap {
  height: 97px;
}
</style>
<style lang="scss" scoped>
.xuanze {
  padding: 30px;
}

.coianter {
  width: 100%;
  background-color: #f4f4f4f4;
  height: 100%;
}

.idteail {
  margin: 0 0 0px 0px;
  width: 100%;
  // height:60px;
  background-color: #ffffff;

  // box-shadow: #cccbcb 0px -1px 10px;
  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
  }
}

.title {
  background: white;
  width: 100%;

  .titleicon {
    margin: 10px;
    width: 4px;
    height: 24px;
    //background: #3082E2;
  }

  .titletext {
    font-size: 22px;
    font-family: Adobe Heiti Std;
    //color: #3082E2;
    line-height: 65px;
  }
}

.comit {
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;

  .baocun {
    margin: 0 25px;
  }
}

.name_input {
  width: 100%;
}

.name_input>.el-form-item {
  width: 100%;
}

.name_input>.el-form-item .el-input {
  width: 100%;
}

.borderstyle {
  border: 1px solid;
}

.centerstyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexstyle {
  display: flex;
  flex-direction: row;
}

.tableTitle-tr {
  height: 60px;
  font-size: 22px;

  .tdtitle {
    width: 200px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .tdcontent {
    width: 280px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

.tableTitle-tr2 {
  font-size: 16px;

  .tdtitle {
    width: 150px;
    border: 1px solid black;
    //background: #EAF2FC;
    text-align: center;
  }

  .shijianstyle {
    text-align: center;
  }

  .tdcontent {
    width: 500px;
  }

  .tdcontent2 {
    width: 300px;
  }

  .bianzhitd {
    width: 1415px;
  }
}

table {
  border: 0;
  border-collapse: collapse;
}

td {
  border: 1px solid black;
}

//这里改
.borderstyle {
  border: 1px solid;
}
</style>
